import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Bon Appetit',
    useCase: 'BRAND DEVELOPMENT, GRAPHIC DESIGN',
    description: `I have worked with Bon Appetit for the last 3 years in designing and developing hospitality brands across their many partnership campuses. The brands have ranged from farm to table, burger joints, concession stands and many more. The below is just a taste of the wide variety of branding concepts and design work my team has executed on a daily basis. Brand concepts can be expanded upon if needed.`,
  },
  caseId: 'bon-appetit',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
    'image10.jpg',
    'image11.jpg',
    'image12.jpg',
    'image13.jpg',
    'image14.jpg',
    'image15.jpg',
    'image16.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
